.progress-container {
    box-shadow: 0 4px 5px rgb(0, 0, 0, 0.1);
}

.progress-container,
.progress {
    background-color: #eee;
    border-radius: 5px;
    position: relative;
    height: 7px;
    width: 300px;
}

.progress {
    background-color: #6f39b0;
    width: 0;
    transition: width 0.4s linear;
}

.percentage {
    background-color: #6f39b0;
    border-radius: 5px;
    box-shadow: 0 4px 5px rgb(0, 0, 0, 0.2);
    color: #fff;
    font-size: 12px;
    padding: 4px;
    position: absolute;
    top: 20px;
    left: 0;
    transform: translateX(-50%);
    width: 40px;
    text-align: center;
    transition: left 0.4s linear;
}

.percentage::after {
    background-color: #6f39b0;
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    height: 10px;
    width: 10px;
    z-index: -1;
}

.ck.ck-editor__editable_inline{
    height: 150px;
}

.css-1nvdc5e-MuiCardHeader-root .MuiCardHeader-title{
    text-align: center;
    text-transform: uppercase;
    font-weight: 800;
}

/*
  *
  *
  *
  *
  *
  *
  *
  *
  *
  *
  *
  *
  *
  *
  *
  *
  *
  */
.timeDurationBox {
    display: inline-block;
    border: 1px solid #e1e1e1;
    padding: 70px 50px;
    font-size: 20px;
}
.timeDurationBox:hover {
    cursor: pointer;
}
.timeDurationMainDiv {
    margin: 150px 0;
    text-align: center;
}
.activeTimeDuration{
  background-color: #5e35b1;
  color: #ede7f6;
}